





























































import { Component, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import SearchBar from "@/components/dossier/SearchBar.vue";
import { Account } from "@/interfaces/Account";
import DjlMultiselect from "@/components/form-components/djlMultiselect.vue";

import DocumentList from "@/components/dossier/DocumentList.vue";
import mFiles, { DossierDocument } from "@/api/mFiles";
import { AxiosResponse } from "axios";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

import { TranslateResult } from "vue-i18n";
import accessControl from "@/api/access_control";
import { resolvePromiseSettledResult } from "@/utils/promises";

@Component({
  props: { selectedAccountId: String },
  components: {
    DocumentList,
    Loader,
    SearchBar,
    DjlMultiselect,
  },
})
@Translatable("dossier")
export default class CustomerDossier extends TranslatableComponent {
  private isLoadingAccounts: boolean = true;
  private isLoadingDossierDocuments: boolean = false;
  private documents: DossierDocument[] = [];
  private accounts: Account[] = [];
  private selectedAccount: Account | null = null;
  private filter: string = "";
  private isShowMFilesDmsFileUpload: boolean = false;

  onSearchBarChange(value: string) {
    this.filter = value;
  }

  get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  get hasSelectedAccount(): boolean {
    return !!this.selectedAccount;
  }

  get hasActiveFilter(): boolean {
    return !!this.filter;
  }

  get noResultsMessage(): TranslateResult | string {
    return this.hasSelectedAccount
      ? this.hasDocuments
        ? this.hasActiveFilter
          ? this.translated_view_value("no_documents_in_selection")
          : ""
        : this.translated_view_value("selected_entity_has_no_documents")
      : "";
  }

  mounted() {
    this.isShowMFilesDmsFileUpload =
      process.env.VUE_APP_SHOW_MFILES_DMS_FILE_UPLOAD?.toLowerCase() === "true";

    accessControl.accounts
      .accountsWithRole("dossier")
      .then((response: AxiosResponse<Account[]>) => {
        this.accounts = response.data;
        this.renderDossierDocuments();
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_api_value("access_control.error.fetch_accounts")
        );
        this.accounts = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
      });
  }

  @Watch("$props.selectedAccountId")
  async renderDossierDocuments() {
    let accountsToFindDocumentsFor = this.determineAccountsFindDocumentsFor();

    this.isLoadingDossierDocuments = true;
    await this.loadDocuments(accountsToFindDocumentsFor);
    this.isLoadingDossierDocuments = false;
  }

  determineAccountsFindDocumentsFor(): Account[] {
    this.selectedAccount = null;

    for (let account of this.accounts)
      if (account.id === this.$props.selectedAccountId)
        this.selectedAccount = account;

    return this.selectedAccount ? [this.selectedAccount] : this.accounts;
  }

  async loadDocuments(
    accountsToFindDocumentsFor: Account[]
  ): Promise<DossierDocument[]> {
    this.documents = [];
    const dossierDocumentsPromises: Promise<DossierDocument[]>[] = [];

    accountsToFindDocumentsFor.forEach((account: Account) => {
      dossierDocumentsPromises.push(this.getDossierDocuments(account));
    });

    return Promise.allSettled(dossierDocumentsPromises)
      .then(
        (results) => (this.documents = resolvePromiseSettledResult(results))
      )
      .catch(() => [] as DossierDocument[]);
  }

  getDossierDocuments(selectedAccount: Account): Promise<DossierDocument[]> {
    return mFiles.getDocumentsWithAccount(selectedAccount).catch(() => {
      this.$snotify.error(
        this.translated_view_value("can_not_get_documents", {
          name: selectedAccount.name,
        })
      );

      return [];
    });
  }

  onChange(selectedAccount: Account) {
    this.$router.push({
      name: "dossier",
      query: {
        selectedAccountIds: selectedAccount ? [selectedAccount.id] : [],
      },
    });
  }
}
